import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

//thermal image
import thermal_arctic from "@/assets/images/thermal_img/thermal_arctic.png";
import thermal_black_hot from "@/assets/images/thermal_img/thermal_black_hot.png";
import thermal_cold_spot from "@/assets/images/thermal_img/thermal_cold_spot.png";
import thermal_fulgurite from "@/assets/images/thermal_img/thermal_fulgurite.png";
import thermal_gray from "@/assets/images/thermal_img/thermal_gray.png";
import thermal_hot_iron from "@/assets/images/thermal_img/thermal_hot_iron.png";
import thermal_hot_metal from "@/assets/images/thermal_img/thermal_hot_metal.png";
import thermal_hot_spot from "@/assets/images/thermal_img/thermal_hot_spot.png";
import thermal_iron_red from "@/assets/images/thermal_img/thermal_iron_red.png";
import thermal_medical from "@/assets/images/thermal_img/thermal_medical.png";
import thermal_rainbow from "@/assets/images/thermal_img/thermal_rainbow.png";
import thermal_rainbow_1 from "@/assets/images/thermal_img/thermal_rainbow_1.png";
import thermal_rainbow_2 from "@/assets/images/thermal_img/thermal_rainbow_2.png";
import thermal_tint from "@/assets/images/thermal_img/thermal_tint.png";
import thermal_white_hot from "@/assets/images/thermal_img/thermal_white_hot.png";
import thermal_no_type from "@/assets/images/thermal_img/thernal_no_type.png";

import user_img from "@/assets/images/ico_user.png";

import drone_image from "@/assets/images/drone_img/drone_image_1.png";

//drone dji image
import dji_air_2s from "@/assets/images/drone_img/dji_air_2s.png";
import dji_matrice_300 from "@/assets/images/drone_img/dji_matrice_300.png";
import dji_matrice_300_RTK from "@/assets/images/drone_img/dji_matrice_300_RTK.png";
import dji_matrice_350_RTK from "@/assets/images/drone_img/dji_matrice_350_RTK.png";
import dji_matrice_m300 from "@/assets/images/drone_img/dji_matrice_m300.png";
import dji_mavic_2 from "@/assets/images/drone_img/dji_mavic_2.png";
import dji_mavic_2_pro from "@/assets/images/drone_img/dji_mavic_2_pro.png";
import dji_mavic_air_2s from "@/assets/images/drone_img/dji_mavic_air_2s.png";
import dji_enterprise from "@/assets/images/drone_img/dji-enterprise.png";
import dji_enterprise_advance from "@/assets/images/drone_img/dji-enterprise-advance.png";
import dji_enterprise_advance_dual from "@/assets/images/drone_img/dJI-enterprise-advance-Dual.png";
import dji_mavic_2_zoom from "@/assets/images/drone_img/dji_mavic_2_zoom.png";
import dji_fpv from "@/assets/images/drone_img/dji_fpv.png"; //추후 추가
import dji_mavic_3_enterprise from "@/assets/images/drone_img/dji-mavic3-enterprise.png"; //추후 추가
import dji_mavic_3_Thermal from "@/assets/images/drone_img/dji_mavic3_Thermal.png"; //추후 추가
import dji_mavic_3_enterprise_multispectral from "@/assets/images/drone_img/dji_mavic_3_enterprise_multispectral.png"; //추후 추가
import dji_matrice_30T from "@/assets/images/drone_img/dji_matrice_30T.png"; //추후 추가
import dji_matrice_30 from "@/assets/images/drone_img/dji_matrice_30T.png"; //추후 추가
import dji_mini_2 from "@/assets/images/drone_img/dji_mini_2.png";
import dji_phantom4_pro from "@/assets/images/drone_img/dji_phantom4_pro.png";
import dji_phantom4_pro_v2 from "@/assets/images/drone_img/dji_phantom4_pro_v2.png";
import dji_inspire_v2 from "@/assets/images/drone_img/dji_inspire_v2.png";
import dji_m210_v2 from "@/assets/images/drone_img/dji_m210_v2.png";
import dji_matrice_3td from "@/assets/images/drone_img/matrice-3td.png";
import dji_matrice_3d from "@/assets/images/drone_img/matrice_3d.png";
import dji_mini_3 from "@/assets/images/drone_img/dji_mini_3.png";
import dji_mini_3_pro from "@/assets/images/drone_img/dji_mini_3_pro.png";

//drone yuneec image
import yuneec_h520 from "@/assets/images/drone_img/yuneec_h520.png";
import yuneec_typhoon_h_plus_rs from "@/assets/images/drone_img/yuneec_typhoon-h-plus-rs.png";

//drone parrot image
import parrot_anafi from "@/assets/images/drone_img/parrot_anafi.png";
//import parrot_anafi_ai from "@/assets/images/drone_img/parrot_anafi_ai.png"; //추후 추가

//drone autel image
import autel_evo2_pro from "@/assets/images/drone_img/autel_evo2_pro.png";
import moment from "moment/moment";

//query전달 이미지등록 없음
export function createDevice(data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + "/device", data, { headers });
}

//query 전달
export function updateDevice(deviceId, data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.patch(baseUrl + baseApiVersion + `/device/${deviceId}`, data, { headers });
}

// ok
export function fetchDeviceSerial(serial) {
  return axios.get(baseUrl + baseApiVersion + `/device/checked/${serial}`, {
    headers,
  });
}

export function getDeviceImgUrl(accountId, deviceId) {
  return baseUrl + baseApiVersion + `/device/account/${accountId}/image/${deviceId}`
}

//ok
export function fetchDeviceDetail(deviceId) {
  return axios.get(baseUrl + baseApiVersion + `/device/${deviceId}`, { headers });
}
// ok
export function fetchDeviceList(params) {
  return axios.get(baseUrl + baseApiVersion + `/device/list`, {
    params,
    headers,
  });
}
//ok
export function fetchManufacturerList(params) {
  return axios.get(baseUrl + baseApiVersion + `/device/manufacturer/list`, {
    params,
    headers,
  });
}
//ok
export function fetchModelList(params) {
  return axios.get(baseUrl + baseApiVersion + `/device/model/list`, { params, headers });
}

export function fetchDeviceLogList(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/device/log/list`,
    { headers, params }
  );
}

export function deleteDevice(deviceId) {
  return axios.delete(baseUrl + baseApiVersion + `/device/${deviceId}`, { headers });
}

export function fetchDeviceDataInfo(refId, resultType, params){
  return axios.get(baseUrl + baseApiVersion + `/device/data/${refId}/resultType/${resultType}`, { params, headers });
}

export function tempFetchStationThumbnail(accountId, refId) {
  return axios.get(baseUrl + baseApiVersion + `/device/data/accountId/${accountId}/thumbnail/${refId}?date=${moment(Date()).format("YYYY.MM.DD.HH.mm.ss")}`, {
    headers: { "Content-Type": "image/jpg" },
  });
}

export function tempFetchStationThumbnailUrl(accountId, refId) {

  return baseUrl + baseApiVersion + `/device/data/accountId/${accountId}/thumbnail/${refId}?date=${moment(Date()).format("YYYY.MM.DD.HH.mm.ss")}`
}

export function getDeviceImgDefault(manufacturerId, modelId){
  var result = null;

  switch (manufacturerId){
    case "DJI":
      switch(modelId) {
        case "MavicAir2":
          result = dji_air_2s;
          break;
        case "Matrice300":
          result = dji_matrice_300;
          break;
        case "MatriceM300":
          result = dji_matrice_m300;
          break;
        case "Matrice300RTK":
          result = dji_matrice_300_RTK;
          break;
        case "Matrice350RTK":
          result = dji_matrice_350_RTK;
          break;
        case "DJIAir2S":
          result = dji_mavic_air_2s;
          break;
        case "Phantom4Pro":
          result = dji_phantom4_pro;
          break;
        case "Phantom4ProV2":
          result = dji_phantom4_pro_v2;
          break;
        case "Mavic2Pro":
          result = dji_mavic_2_pro;
          break;
        case "Mavic2S":
          result = drone_image;
          break;
        case "Mavic2":
          result = dji_mavic_2;
          break;
        case "Mavic2Enterprise":
          result = dji_enterprise;
          break;
        case "Mavic2EnterpriseAdvanced":
          result = dji_enterprise_advance;
          break;
        case "Mavic2EnterpriseDual":
          result = dji_enterprise_advance_dual;
          break;
        case "DJIMavic3EnterpriseSeries":
          result = dji_mavic_3_enterprise;
          break;
        case "DJIMavic3Enterprise":
          result = dji_mavic_3_enterprise;
          break;
        case "DJIMavic3EnterpriseThermal":
          result = dji_mavic_3_Thermal;
          break;
        case "DJIMavic3EnterpriseMultispectral":
          result = dji_mavic_3_enterprise_multispectral;
          break;
        case "Matrice30T" :
          result = dji_matrice_30T;
          break;
        case "Matrice30" :
          result = dji_matrice_30;
          break;
        case "Matrice30Series" :
          result = dji_matrice_30;
          break;
        case "INSPIRE2":
          result = dji_inspire_v2;
          break;
        case "M210V2":
          result = dji_m210_v2;
          break;
        case "DJIMini2":
          result = dji_mini_2;
          break;
        case "Mavic2Zoom":
          result = dji_mavic_2_zoom;
          break;
        case "DJIFPV":
          result = dji_fpv;
          break;
        case "M3D":
          result = dji_matrice_3d;
          break;
        case "M3TD":
          result = dji_matrice_3td;
          break;
        case "DJIMini3" :
          result = dji_mini_3
          break;
        case "DJIMini3Pro" :
          result = dji_mini_3_pro
          break;
      }
      break;
    case "Autel":
      switch(modelId) {
        case "EVO2":
          result = autel_evo2_pro;
          break;
      }
      break;
    case "Yuneec":
      switch(modelId) {
        case "YuneecH520":
          result = yuneec_h520;
          break;
        case "YuneecTyphoonHPlusRS":
          result = yuneec_typhoon_h_plus_rs;
          break;
      }
      break;
    case "Parrot":
      switch(modelId) {
        case "ParrotANAFI":
          result = parrot_anafi;
          break;
      }
      break;
  }

  if(result == null) {
    result = drone_image;
  }
  return result;
}

export function getMobileImgDefault(deviceType) {
  var result = null;

  switch (deviceType){
    case "Mobile":
    result = user_img;
    break;
  }
  return result;
}

export function getThermalViewNameToImage(name) {
  var result = null
  switch (name) {
    case "WhiteHot" :
      result = thermal_white_hot
      break;
    case "BlackHot" :
      result = thermal_black_hot
      break;
    case "Rainbow" :
      result = thermal_rainbow
      break;
    case "Rainbow1" :
      result = thermal_rainbow_1
      break;
    case "IronRed" :
      result = thermal_iron_red
      break;
    case "Arctic" :
      result = thermal_arctic
      break;
    case "Fulgurite":
      result = thermal_fulgurite
      break;
    case "HotIron" :
      result = thermal_hot_iron
      break;
    case "Rainbow2" :
      result = thermal_rainbow_2
      break;
    case "Tint" :
      result = thermal_tint
      break;
    case "Medical" :
      result = thermal_medical
      break;
    case "HotSpot" :
      result = thermal_hot_spot
      break;
    case "Gray" :
      result = thermal_gray
      break;
    case "HotMetal" :
      result = thermal_hot_metal
      break;
    case "ColdSpot" :
      result = thermal_cold_spot
      break;
    default :
      result = thermal_no_type;
  }
  return result;
}
