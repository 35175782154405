import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchStatisticsEventList(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/statistics/event/list`,
    {
      headers,
        params,
    }
  );
}


export function fetchStatisticsDeviceList(params) {
  return axios.get(
      baseUrl +  baseApiVersion + `/statistics/device/list`,
      {
        headers,
        params,
      }
  );
}

export function fetchStatisticsWorkerList(params) {
  return axios.get(
    baseUrl +  baseApiVersion + `/statistics/worker/list`,
    {
      headers,
      params,
    }
  );
}

export function fetchChannelHistoryStatisticsList(params) {
  return axios.get( baseUrl + baseApiVersion + `/statistics/live/list`,
    {
      headers,
        params
    }
  );
}

export function fetchStatisticsEventJoinCountList(eventId, params) {
    return axios.get(
        baseUrl +  baseApiVersion + `/statistics/event/${eventId}/join/count/list`,
        {
            headers,
            params,
        }
    );
}

export function fetchAddrList(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/statistics/event/addr/list`,
    { headers,
        params}
  );
}

export function fetchWeekTraffic(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/statistics/traffic`,
    { headers,
        params}
  );
}

export function fetchVideoTraffic(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/statistics/video/traffic}`,
    { headers,
        params}
  );
}
